<script setup lang="ts">
import { onMounted } from 'vue';
import { useLikes } from '@/composables/useBlogLikes';
import { formatFirebaseTimestamp } from '@/helpers/index';

// eslint-disable-next-line no-undef
defineProps({ post: Object });

const { initializeLikes, isLiked, toggleLike } = useLikes();

onMounted(() => {
  initializeLikes();
});
</script>

<template>
  <a
    class="bg-white shadow rounded-lg overflow-hidden hover:opacity-90 transition-all"
    v-if="post"
    :href="'/blog/' + post.slug"
  >
    <img :src="post?.coverImageUrl" class="object-cover h-52 w-full" alt="" />
    <div class="p-3">
      <span class="block text-slate-400 font-semibold text-sm ">{{
        formatFirebaseTimestamp(post?.createdAt)
      }}</span>
      <h3
        class="mt-3 font-bold text-lg pb-4 border-b border-slate-300  h-28"
      >
        {{ post?.title }}
      </h3>
      <div class="flex mt-4 mb-3 gap-4 items-center">
        <div class="flex items-center gap-1">
          <i class="pi pi-eye text-black"></i>
          <p class="text-black text-sm">{{ post?.views ?? 0 }}</p>
        </div>
        <div class="flex items-center gap-1">
          <!-- <i class="pi pi-heart text-black"></i>
          <p class="text-black text-sm">12</p> -->
          <i
            :class="
              isLiked(post.id)
                ? 'pi pi-heart-fill text-red-500'
                : 'pi pi-heart text-black'
            "
          ></i>
          <p class="text-black text-sm">{{ post?.likes ?? 0 }}</p>
        </div>
        <!-- <div class="flex items-center gap-1">
          <i class="pi pi-comment text-black"></i>
          <p class="text-black text-sm">18</p>
        </div> -->
      </div>
    </div>
  </a>
</template>

<style scoped></style>
