<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { generateCsv } from '@/helpers';
import UserService from '@/service/user.service';
import RequestService from '@/service/request.service';

const router = useRouter();

const users = ref<[any]>();
const requests = ref<[any]>();

onMounted(async () => {
  if (!localStorage.getItem('loggedIn')) {
    router.push({ name: 'admin-login' });
    return;
  }
  try {
    users.value = await UserService.getUsers();
    requests.value = await RequestService.getAll();
  } catch (error) {
    console.error(error);
  }
});

async function downloadCsv(collection: string) {
  await generateCsv();
}
</script>
<template>
  <div class="flex flex-col p-5">
    <div class="h-screen">
      <div class="flex-1 flex items-center justify-center mb-5">
        <img
          src="@/assets/gold-logo-transparent.png"
          alt="TALAB - Ultimate Student App in London Logo"
          class="w-32 mx-auto"
        />
        <div
          className="px-3 py-3 text-indigo-600 bg-indigo-50 rounded-lg duration-150 hover:bg-indigo-100 active:bg-indigo-200 flex items-center cursor-pointer"
          @click="router.push({ name: 'admin-dashboard-events' })"
        >
          <p className="mr-1">Add Event</p>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 12h14m-7 7V5"
            />
          </svg>
        </div>
        <div
          className="px-3 py-3 text-indigo-600 bg-indigo-50 rounded-lg duration-150 hover:bg-indigo-100 active:bg-indigo-200 flex items-center cursor-pointer ml-3"
          @click="router.push({ name: 'admin-dashboard-services' })"
        >
          <p className="mr-1">Add Service</p>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 12h14m-7 7V5"
            />
          </svg>
        </div>
      </div>
      <div class="flex gap-5">
        <div
          class="block w-64 p-6 border rounded-xl bg-gray-800 border-gray-700 hover:bg-gray-700"
        >
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 text-white">
            {{ users?.length ?? "Loading..." }}
          </h5>
          <p class="font-normal text-white">User Accounts</p>
        </div>
        <div
          class="block w-64 p-6 border rounded-xl bg-zinc-800 border-gray-700 hover:bg-zinc-900"
        >
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 text-white">
            {{ requests?.length ?? "Loading..." }}
          </h5>
          <p class="font-normal text-white">Requests</p>
        </div>

      </div>
    </div>
    <table class="w-full text-sm text-white table-auto mb-5" v-if="users && users.length > 0">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50">
        <tr class="bg-gray-800 text-white">
          <th class="px-4 py-2">ID</th>
          <th class="px-4 py-2">Fullname</th>
          <th class="px-4 py-2">Email</th>
          <th class="px-4 py-2">University</th>
          <th class="px-4 py-2">Phone</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in users"
          :key="user.id"
          class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
        >
          <td class="border px-4 py-2">{{ user.id }}</td>
          <td class="border px-4 py-2">{{ user.fullName }}</td>
          <td class="border px-4 py-2">{{ user.email }}</td>
          <td class="border px-4 py-2">{{ user.university }}</td>
          <td class="border px-4 py-2">{{ user.phoneNumber }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else class="mt-3 text-center">No users found.</div>
    <button
      @click="downloadCsv('users')"
      class="text-lg text-black p-2 border border-black w-fit rounded-lg cursor-pointer"
    >
      Download users (csv)
    </button>
  </div>
</template>
