/* eslint-disable import/prefer-default-export */
// composables/useBlog.ts
import { ref } from 'vue';
import {
  saveBlog,
  fetchAllBlogs,
  fetchBlogBySlug,
} from '@/service/blog.service';
import { generateSlug } from '@/helpers';

export const useBlog = () => {
  const blogs = ref<any[]>([]);
  const blog = ref<any>(null);
  const isLoading = ref(false);

  // Function to save a new blog post
  const createBlog = async (
    title: string,
    content: string,
    metaDescription: string,
    coverImageUrl: string,
  ) => {
    const slug = generateSlug(title);
    await saveBlog(title, content, slug, metaDescription, coverImageUrl);
  };

  // Function to fetch all blog posts
  const getAllBlogs = async () => {
    isLoading.value = true;
    blogs.value = await fetchAllBlogs();
    isLoading.value = false;
  };

  // Function to fetch a single blog post by slug
  const getBlogBySlug = async (slug: string) => {
    isLoading.value = true;
    blog.value = await fetchBlogBySlug(slug);
    isLoading.value = false;
  };

  return {
    blogs,
    blog,
    createBlog,
    getAllBlogs,
    getBlogBySlug,
    isLoading,
  };
};
