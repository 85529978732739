/* eslint-disable import/prefer-default-export */
// composables/useLikes.ts
import { ref } from 'vue';
import { updateLikeCount } from '@/service/blog.service';

export const useLikes = () => {
  const likedPosts = ref<string[]>([]); // Stores liked post IDs in local storage

  // Function to initialize likes from local storage
  const initializeLikes = () => {
    const storedLikes = localStorage.getItem('likedPosts');
    if (storedLikes) {
      likedPosts.value = JSON.parse(storedLikes);
    }
  };

  // Function to check if a post is liked
  const isLiked = (blogId: string): boolean => likedPosts.value.includes(blogId);

  // Function to toggle like status
  const toggleLike = async (blogId: string, currentLikes: number) => {
    const postLiked = isLiked(blogId);

    if (postLiked) {
      // If already liked, dislike the post
      likedPosts.value = likedPosts.value.filter((id) => id !== blogId);
      await updateLikeCount(blogId, -1); // Decrement like count in Firestore
    } else {
      // If not liked, like the post
      likedPosts.value.push(blogId);
      await updateLikeCount(blogId, 1); // Increment like count in Firestore
    }

    // Update local storage
    localStorage.setItem('likedPosts', JSON.stringify(likedPosts.value));
  };

  return {
    likedPosts,
    initializeLikes,
    isLiked,
    toggleLike,
  };
};
