import { useQuery } from '@tanstack/vue-query';
import { Ref } from 'vue';
import { fetchPopularBlogs } from '@/service/blog.service';

export default function usePopularBlogs(currentBlog: Ref<any>) {
  return useQuery({
    queryKey: ['popularBlogs', currentBlog?.value?.slug],
    queryFn: async () => fetchPopularBlogs(currentBlog?.value?.slug),
    staleTime: 1000 * 60 * 10, // Keep data fresh for 5 minutes
    enabled: !!currentBlog.value,
  });
}
