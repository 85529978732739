<template>
  <ckeditor
    v-model="editorData"
    :editor="editor"
    :config="editorConfig"
    @input="onContentChange"
  />
</template>

<script>
import {
  ClassicEditor,
  AccessibilityHelp,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  Bold,
  CKBox,
  CKBoxImageEdit,
  CloudServices,
  Code,
  CodeBlock,
  Essentials,
  GeneralHtmlSupport,
  Heading,
  HtmlComment,
  HtmlEmbed,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  Paragraph,
  PictureEditing,
  SelectAll,
  ShowBlocks,
  SourceEditing,
  TextTransformation,
  Mention,
  BlockQuote,
  HorizontalLine,
  Undo,
} from 'ckeditor5';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import 'ckeditor5/ckeditor5.css';

export default {
  components: {
    Ckeditor,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorData: '<p>Hello from CKEditor 5 in Vue!</p><br><br><br>',
      editorConfig: {
        plugins: [
          AccessibilityHelp,
          Autoformat,
          AutoImage,
          AutoLink,
          Autosave,
          Bold,
          CKBox,
          CKBoxImageEdit,
          CloudServices,
          Essentials,
          GeneralHtmlSupport,
          Heading,
          HtmlComment,
          HtmlEmbed,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsert,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          Italic,
          Link,
          LinkImage,
          List,
          ListProperties,
          Paragraph,
          PictureEditing,
          SelectAll,
          ShowBlocks,
          SourceEditing,
          HorizontalLine,
          TextTransformation,
          Undo,
          BlockQuote,
          Mention,
        ],
        toolbar: [
          'undo',
          'redo',
          '|',
          'sourceEditing',
          'showBlocks',
          '|',
          'heading',
          '|',
          'bold',
          'italic',
          'code',
          '|',
          'link',
          'insertImage',
          'ckbox',
          'insertTable',
          'horizontalLine',
          'htmlEmbed',
          '|',
          'bulletedList',
          'numberedList',
          'blockquote',
        ],
        // licenseKey: "<YOUR_LICENSE_KEY>",
        // Other configuration options...
      },
    };
  },
  methods: {
    // This method is triggered every time the content of the editor changes
    onContentChange() {
      // Emit the updated content to the parent component
      this.$emit('update-content', this.editorData);
    },
  },
};
</script>
