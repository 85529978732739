<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const userName = ref('');
const password = ref('');
const errorMsg = ref('');
const isLoading = ref(false);

function signIn() {
  isLoading.value = true;
  console.log(password.value);
  if (userName.value === 'Talab7' && password.value === 'Talabwith7figuresin20s$') {
    localStorage.setItem('loggedIn', 'TRUE');
    router.push({ name: 'admin-dashboard' });
  } else {
    errorMsg.value = 'Credentials are incorrect. Please try again';
  }
  isLoading.value = false;
}
</script>
<template>
  <main class="w-full flex text-inter text-center">
    <div class="relative flex-1 hidden items-center justify-center h-screen bg-gray-900 lg:flex">
      <div class="relative z-10 w-full max-w-lg">
        <img src="@/assets/gold-logo-transparent.png" alt="TALAB Logo" class="w-32 mx-auto" />
        <div class="mt-10 space-y-2">
          <h3 class="text-white text-3xl font-bold">Ultimate Student Concierge App</h3>
          <p class="text-gray-300 text-sm">
            Welcome to Admin Dashboard, please use your credentials to sign in
          </p>
        </div>
      </div>
      <div
        class="absolute inset-0 my-auto h-[500px]"
        style="
          background: linear-gradient(
            152.92deg,
            rgba(188, 136, 9, 0.6) 4.54%,
            rgba(188, 156, 9, 0.26) 34.2%,
            rgba(188, 136, 9, 0.1) 77.55%
          );
          filter: blur(118px);
        "
      ></div>
    </div>
    <div class="flex-1 flex justify-center items-center pb-20 h-screen">
      <div class="w-full max-w-md space-y-8 px-4 bg-white text-gray-600 sm:px-0">
        <img
          src="@/assets/gold-logo-transparent.png"
          alt="TALAB Logo"
          class="lg:hidden mx-auto w-32"
        />
        <div class="relative mt-22">
          <span class="block w-full h-px bg-gray-300"></span>
          <p class="inline-block w-fit text-sm bg-white px-2 absolute -top-2 inset-x-0 mx-auto">
            Sign in
          </p>
        </div>
        <div class="space-y-5">
          <div>
            <label class="font-regular text-sm">Username</label>
            <input
              v-model="userName"
              type="text"
              required
              class="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
            />
          </div>
          <div>
            <label class="font-regular text-sm">Password</label>
            <input
            v-model="password"
              type="password"
              required
              class="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
            />
          </div>
          <p v-if="errorMsg" class="text-xs text-red-600">{{ errorMsg }}</p>
          <button
            class="w-full px-4 py-2 text-white font-medium bg-[#C4992A] hover:bg-[#DBCC8B] active:bg-[#DBCC8B] rounded-lg duration-150"
            @click="signIn"
          >
            <span v-if="!isLoading">Sign in</span>
            <svg
              v-else
              aria-hidden="true"
              class="w-6 h-6 mx-auto text-gray-200 animate-spin dark:text-gray-600 fill-white"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </main>
</template>
