import {
  addDoc, collection, getDocs, getFirestore, Timestamp,
} from 'firebase/firestore';

// src/utils/generateCsv.js
// import { parse } from 'json2csv';

import { json2csv } from 'json-2-csv';
import { db } from '@/config/firebase';

export const generateCsv = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const users: any = [];
    let index = 1;

    querySnapshot.forEach((doc) => {
      // eslint-disable-next-line no-plusplus
      users.push({ id: index++, ...doc.data() });
    });

    // const csv = parse(users);
    const csv = await json2csv(users);
    const blob = new Blob([csv], { type: 'text/csv' });

    // Create a link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'users.csv';

    // Append the link to the body
    document.body.appendChild(link);

    // Simulate click to download
    link.click();

    // Cleanup: remove the link
    document.body.removeChild(link);
  } catch (e) {
    console.log(e);
  }
};

// export const generateCsvFormatTime = async (collectionName = 'userbase') => {
//   try {
//     const querySnapshot = await getDocs(collection(db, collectionName));
//     const users:any = [];
//     let index = 1;

//     querySnapshot.forEach((doc) => {
//       const data = doc.data();
//       // eslint-disable-next-line no-underscore-dangle
//       const createdAt = new Date(data._createdAt.seconds * 1000 + data._createdAt.nanoseconds / 1000000);
//       users.push({
//         // eslint-disable-next-line no-plusplus
//         id: index++,
//         ...data,
//         createdAt: createdAt.toLocaleString(),
//       });
//     });

//     const csv = await json2csv(users);

//     // Create a Blob from the CSV data
//     const blob = new Blob([csv], { type: 'text/csv' });

//     // Create a link element
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.download = 'userbase.csv';

//     // Append the link to the body
//     document.body.appendChild(link);

//     // Simulate click to download
//     link.click();

//     // Cleanup: remove the link
//     document.body.removeChild(link);
//   } catch (error) {
//     console.error('Error generating CSV: ', error);
//   }
// };

export function getQueryParams(): { [key: string]: string } {
  const queryParams: { [key: string]: string } = {};
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  urlParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
}

export function resizeImage(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    img.onload = () => {
      const aspectRatio = img.width / img.height;
      if (img.width > 1250) {
        canvas.width = 1250;
        canvas.height = 1250 / aspectRatio;
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }

      if (ctx) {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, { type: file.type });
            resolve(resizedFile);
          } else {
            reject(new Error('Canvas to Blob conversion failed'));
          }
        }, file.type);
      } else {
        reject(new Error('Canvas context is not available'));
      }
    };

    img.onerror = (error) => reject(error);
    img.src = URL.createObjectURL(file);
  });
}

export function formatFirebaseTimestamp(timestamp: Timestamp): string {
  const date = timestamp.toDate(); // Convert Firebase Timestamp to JavaScript Date object

  // Options for formatting the date
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  // Format the date using Intl.DateTimeFormat
  return new Intl.DateTimeFormat('en-US', options).format(date);
}

// Helper function to generate a slug
export const generateSlug = (title: string): string => {
  const stopWords = new Set([
    'a',
    'an',
    'the',
    'and',
    'but',
    'or',
    'on',
    'in',
    'with',
    'of',
    'to',
    'for',
    'at',
    'by',
    'from',
    'up',
    'down',
    'out',
    'over',
    'under',
    'again',
    'further',
    'then',
    'once',
  ]);
  const maxWords = 5;

  return title
    .toLowerCase()
    .replace(/[^a-z0-9\s]+/g, '') // Remove non-alphanumeric characters, except spaces
    .trim()
    .split(/\s+/) // Split into words
    .filter((word) => !stopWords.has(word)) // Exclude stop words
    .slice(0, maxWords) // Limit to the first `maxWords` words
    .join('-') // Join words with hyphens
    .replace(/(^-|-$)/g, ''); // Remove leading/trailing hyphens
};

export default generateCsv;
