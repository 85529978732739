<script setup lang="ts">
import { onMounted } from 'vue';
import * as amplitude from '@amplitude/analytics-browser';
import { getQueryParams } from './helpers';

// Use onMounted to track the page view when the component is mounted
onMounted(() => {
  const queryParams = getQueryParams();
  const currentUrl = window.location.href;

  // Exclude URLs that contain "admin-dashboard"
  if (!currentUrl.includes('admin-dashboard')) {
    amplitude.track('page_view', {
      ...queryParams,
      url: currentUrl,
    });
  }
});
</script>
<template>
  <router-view />
</template>

<style lang="scss"></style>
