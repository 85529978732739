<!-- eslint-disable no-shadow -->
<script setup lang="ts">
import { computed, ref } from 'vue';
import { useBlog } from '@/composables/useBlog';
import ContentEditor from '@/components/ContentEditor.vue';
import { uploadBlogCoverImage } from '@/service/blog.service';

const title = ref('');
const content = ref('');
const metaDescription = ref('');
const coverImage = ref<File | null>(null);
const editorContent = ref('');

const coverImageDisplay = computed(() => (coverImage.value ? URL.createObjectURL(coverImage.value) : null));

const handleContentUpdate = (content: string) => {
  editorContent.value = content;
};

const onCoverImageChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files && target.files[0]) {
    const [file] = target.files;
    coverImage.value = file;
  }
};

async function submitBlog() {
  const { createBlog } = useBlog(); // Fetching the createBlog function from the composable

  if (!title.value || !editorContent.value || !metaDescription.value) {
    console.log(title.value, editorContent.value, metaDescription.value);
    alert('Please fill out all fields.');
    return;
  }

  let coverImageUrl = '';
  if (coverImage.value) {
    // Upload cover image to Firebase Storage
    coverImageUrl = await uploadBlogCoverImage(coverImage.value);
  }

  await createBlog(
    title.value,
    editorContent.value,
    metaDescription.value,
    coverImageUrl,
  );
  alert('Blog created successfully!');

  // Reset form or redirect after successful submission
  title.value = '';
  content.value = '';
  metaDescription.value = '';
  coverImage.value = null;
}
</script>

<template>
  <div class="flex flex-col w-screen h-screen items-center justify-start p-10">
    <h1 class="text-center font-bold text-3xl mb-10">
      Create a New Blog Post
    </h1>
    <div class="w-[50%] p-10 bg-white rounded-lg">
      <div class="flex flex-col gap-1 mb-4">
        <label>Title</label>
        <input
          v-model="title"
          type="text"
          required
          class="border border-[#BC8809] rounded-md p-1 outline-[#505050]"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label>Meta Description</label>
        <input
          v-model="metaDescription"
          type="text"
          required
          class="border border-[#BC8809] rounded-md p-1 outline-[#505050]"
        />
      </div>
      <div class="flex flex-col gap-1">
        <label for="coverImage">Cover Image</label>
        <input type="file" @change="onCoverImageChange" accept="image/*" />
      </div>
      <div class="flex w-full mt-2" v-if="coverImageDisplay">
        <div>
          <img
            :src="coverImageDisplay"
            alt="Cover Image"
            class="w-60 rounded-lg"
          />
        </div>
      </div>
      <div class="w-[100%] mt-[5%]">
        <ContentEditor @update-content="handleContentUpdate" />
      </div>
      <button
        class="p-3 bg-[#BC8809] text-white w-full rounded-lg mt-5 hover:opacity-80 transition-colors"
        @click="submitBlog"
      >
        Add Blog
      </button>
    </div>
  </div>
</template>
