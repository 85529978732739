/* eslint-disable no-shadow */
// services/blogService.ts
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  updateDoc,
  increment,
  orderBy,
  limit,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '@/config/firebase';
import { resizeImage } from '@/helpers';

const db = getFirestore();

// Function to save a new blog post
export const saveBlog = async (
  title: string,
  content: string,
  slug: string,
  metaDescription: string,
  coverImageUrl: string,
) => {
  try {
    await addDoc(collection(db, 'blogs'), {
      title,
      content,
      slug,
      metaDescription,
      coverImageUrl,
      createdAt: new Date(),
    });
  } catch (error) {
    console.error('Error saving blog post:', error);
  }
};

// Function to fetch all blog posts
export const fetchAllBlogs = async () => {
  const blogs: any[] = [];
  const q = query(collection(db, 'blogs'), orderBy('createdAt', 'desc'));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    blogs.push({ id: doc.id, ...doc.data() });
  });
  return blogs;
};

// Function to fetch a single blog post by slug
export const fetchBlogBySlug = async (slug: string) => {
  const q = query(collection(db, 'blogs'), where('slug', '==', slug));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const blog = querySnapshot.docs[0];
    return { id: blog.id, ...blog.data() };
  }
  return null;
};

export const fetchPopularBlogs = async (currentBlogSlug: string) => {
  try {
    const q = query(
      collection(db, 'blogs'),
      where('slug', '!=', currentBlogSlug), // Exclude the current blog
      orderBy('views', 'desc'),
      limit(3), // Limit to 3 results
    );

    const querySnapshot = await getDocs(q);
    console.log('s');
    const popularBlogs: any[] = [];
    querySnapshot.forEach((doc) => {
      popularBlogs.push({ id: doc.id, ...doc.data() });
    });

    return popularBlogs;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Function to increment the view count
export const incrementViewCount = async (blogId: string) => {
  try {
    const blogRef = doc(db, 'blogs', blogId);
    await updateDoc(blogRef, {
      views: increment(1),
    });
  } catch (error) {
    console.error('Error incrementing view count:', error);
  }
};

// Function to Like/Unlike blog
export const updateLikeCount = async (
  blogId: string,
  incrementValue: number,
) => {
  try {
    const blogRef = doc(db, 'blogs', blogId);
    await updateDoc(blogRef, {
      likes: increment(incrementValue),
    });
  } catch (error) {
    console.error('Error updating like count:', error);
  }
};

export async function uploadBlogCoverImage(file: File): Promise<string> {
  const resizedFile = await resizeImage(file);
  const storageRef = ref(storage, `blog/${file.name}-coverImage`);
  const snapshot = await uploadBytes(storageRef, resizedFile);
  return getDownloadURL(snapshot.ref);
}
