import {
  collection, getDocs, query, Timestamp, updateDoc, where,
} from 'firebase/firestore';

import { db } from '@/config/firebase';

class UserService {
  static async getUsers() {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const users: any = [];
    let index = 1;

    querySnapshot.forEach((doc: any) => {
      // eslint-disable-next-line no-plusplus
      users.push({ id: index++, ...doc.data() });
    });
    return users;
  }

  static async getUserPushTokens() {
    const querySnapshot = await getDocs(collection(db, 'users'));
    const pushTokens: string[] = [];

    querySnapshot.forEach((doc: any) => {
      const data = doc.data();
      if (data.pushToken) {
        console.log(pushTokens);
        pushTokens.push(data.pushToken);
      }
    });

    return pushTokens;
  }
}

export default UserService;
