import { createApp } from 'vue';

import * as amplitude from '@amplitude/analytics-browser';
import App from './App.vue';
import router from './router';
import './assets/main.css';
import 'primeicons/primeicons.css';
import { createHead } from '@vueuse/head';
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
import { VueQueryPlugin } from '@tanstack/vue-query';

amplitude.init('9dfcf23466f621a704735201c09aca32', { autocapture: false, serverZone: 'EU' });
const headMetadata = createHead();

createApp(App)
  .use(router)
  .use(CkeditorPlugin)
  .use(headMetadata)
  .use(VueQueryPlugin)
  .mount('#app');
