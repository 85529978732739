import {
  collection, getDocs, limit, orderBy, query, Timestamp, updateDoc, where,
} from 'firebase/firestore';

import { db } from '@/config/firebase';

class RequestService {
  static async getAll() {
    const querySnapshot = await getDocs(query(collection(db, 'requests')));
    const requests: any = [];
    let index = 1;

    querySnapshot.forEach((doc: any) => {
      // eslint-disable-next-line no-plusplus
      requests.push({ id: index++, ...doc.data() });
    });
    return requests;
  }
}

export default RequestService;
