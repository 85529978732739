import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LoginView from '@/views/AdminPanel/LoginView.vue';
import AdminPanel from '@/views/AdminPanel/AdminPanelView.vue';
import ServicesView from '@/views/AdminPanel/ServicesView.vue';
import EventsView from '@/views/AdminPanel/EventsView.vue';
import NotificationManager from '@/components/admin-dashboard/NotificationManager.vue';
import BlogView from '@/views/BlogView.vue';
import BlogListingView from '@/views/BlogListingView.vue';
import HomeView from '@/views/HomeView.vue';
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue';
import BlogsManagerView from '@/views/AdminPanel/BlogsManagerView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/admin-dashboard/login',
    name: 'admin-login',
    component: LoginView,
  },
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    component: AdminPanel,
  },
  {
    path: '/admin-dashboard/services',
    name: 'admin-dashboard-services',
    component: ServicesView,
  },
  {
    path: '/admin-dashboard/events',
    name: 'admin-dashboard-events',
    component: EventsView,
  },
  {
    path: '/admin-dashboard/notification-manager',
    name: 'notification-manager',
    component: NotificationManager,
  },
  {
    path: '/admin-dashboard/blog-manager',
    name: 'blog-manager',
    component: BlogsManagerView,
  },
  {
    path: '/privacy-policy',
    name: 'privacy policy',

    component: PrivacyPolicyView,
  },
  { path: '/blog/:slug', component: BlogView },
  { path: '/blog', component: BlogListingView },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
