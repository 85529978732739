<script setup lang="ts">
import { ref } from 'vue';
import axios from 'axios';
import UserService from '@/service/user.service';

// Reactive variables for title and message
const title = ref<string>('');
const message = ref<string>('');

// Error handling
const error = ref<string | null>(null);

const sendNotification = async (expoPushToken: string) => {
  error.value = null;
  try {
    await axios.post('http://localhost:3000/send-notification', {
      title: title.value,
      message: message.value,
      expoPushToken,
    });

    title.value = '';
    message.value = '';
  } catch (err) {
    error.value = 'Failed to send notification';
    console.error(err);
  }
};

const sendNotificationsToAllUsers = async () => {
  try {
    const pushTokens = await UserService.getUserPushTokens();

    // Now you can send notifications to each token
    pushTokens.forEach((token, i) => {
      console.log(i);
      sendNotification(token);
    });
  } catch (e) {
    console.error('Error fetching push tokens:', e);
  }
};
</script>

<template>
  <div class="w-screen px-10">
    <form @submit.prevent="sendNotificationsToAllUsers" class="flex flex-col border border-neutral-600 rounded-xl items-center justify-center" >
      <div class="flex flex-col gap-2 w-96">
        <label for="title">Title</label>
        <input class="rounded-lg border border-black" id="title" v-model="title" type="text" placeholder="Enter title" />
      </div>

      <div class="flex flex-col gap-2 w-96">
        <label for="message">Message</label>
        <textarea class="rounded-lg border border-black" id="message" v-model="message" placeholder="Enter message"></textarea>
      </div>

      <button type="submit" class="w-96 py-3 bg-blue-900 text-white text-center rounded-xl">Send</button>
    </form>

    <div v-if="error">{{ error }}</div>
  </div>
</template>

<style scoped>

label {
  margin-bottom: 5px;
}

input,
textarea {
  margin-bottom: 15px;
  padding: 8px;
}

</style>
